import React from "react";
import e85Cover from "../.././assets/e85/e85Cover.webp";
import e85a from "../.././assets/e85/e85a.webp";
import e85Tech from "../.././assets/e85/E85.pdf"

import e85sec1 from "../.././assets/e85/e85sec1.webp";
import e85sec2 from "../.././assets/e85/e85sec2.webp";
import e85sec3 from "../.././assets/e85/e85sec3.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const E85 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ETEM E85
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-xl tracking-wide"></h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={e85Cover}
                alt="Cover for etem e85"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                Mullion transom curtain wall system with 50 mm profiles front
                view. Suitable for all types of buildings (low, mid and
                high-rise). With its wide variety of profiles this versatile
                curtain wall system can be adapted to meet the requirements of
                any project. The wide range of profiles allows adaptation to the
                specific requirements of the project Easy and fast construction,
                installation and installation of glazing The limited visible
                width of the profiles, 50mm, ensures abundant natural lighting
                inside the building
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>Structural stability</li>
                  <li>Safety</li>
                  <li>High weather performance</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={e85Cover}
              alt="Cover of Etem e85"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      {/* <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-3 gap-2 mx-auto">
              <div class="w-full">
                <img
                  src={e85a}
                  alt="Etem e85 example"
                  className="object-cover w-full h-3/5"
                />
              </div>
            </div>
          </div> */}

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4 ">
                    Class AE
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">
                    CLASS RE 900
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  RESISTANCE TO WIND LOAD
                  <span className="pr-10 block font-normal pb-4">
                    {" "}
                    design load- 1600Pa, safety load- 2400Pa
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  IMPACT RESISTANCE
                  <span className="pr-10 block font-normal pb-4">
                    class I5/E5
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  THERMAL TRANSMITTANCE
                  <span className="pr-10 block font-normal pb-4">
                    Uf = 1,6 W/m2.K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SOUND INSULATION
                  <span className="pr-10 block font-normal pb-4">Rw=44dB</span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              APPLICATIONS
            </h3>
            <p className=" md:pl-32 text-left .leading-8 font-montserrat font-medium  md:text-base tracking-narrow ">
              <ul className="list-disc p-6">
                <li>Cover cap, silicone gap and combined solutions.</li>
                <li>Double and triple glazing options.</li>
                <li>Parallel and projected opening.</li>
                <li>
                  Roof window solution. *Straightforward incorporation of all
                  ETEM systems, both windows and doors.
                </li>
              </ul>
            </p>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>

          
        </div>
      </div>
      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-8xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6   bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img
              src={e85sec1}
              alt="etem e85 section piece"
              className="object-cover "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img
              src={e85sec2}
              alt="etem e85 section piece"
              className="object-cover "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img
              src={e85sec3}
              alt="etem e85 section piece"
              className="object-cover "
            />
          </div>
        </div>
      </div>
      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
           href={e85Tech}
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            E85 Technical Catalogue
          </a>
        </div>
      </div>

     
     

      
    </div>
  );
};

export default E85;
