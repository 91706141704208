import React from "react";
import centerSealCover from "../../../assets/76/centerSeal.webp";
import centerSeala from "../../../assets/76/centerSeala.webp";
import centerSealb from "../../../assets/76/centerSealb.webp";
import centerSealsec1 from "../../../assets/76/centerSealsec1.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Center76 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              KOEMMERLING 76
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Kömmerling 76 center seal standard
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={centerSealCover}
                alt="Picture of a project"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The Kömmerling 76 centre seal system offers all the benefits
                modern windows can give you. This centre seal system with six
                chambers and 76 mm construction depth offers a fascinating
                combination of modern design and perfect profile proportions.
                Whether function, structural properties, insulation values,
                environmental protection, or preservation of value, it fulfills
                the requirements of both today, and above all tomorrow. The
                Kömmerling 76 centre seal system is such an innovative design
                that it can take modern triple glazing or special functional
                glazing with panes up to 50 mm thick. Thanks to intelligent
                fastening technology, the professional installation is fast,
                clean, and effortless.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    A new high-insulation 76 mm system for high energy savings –
                    Uf value = 1.0 W/(m²K) alone for the standard version.
                  </li>
                  <li>
                    Perfected noise protection for enhanced quality of living
                  </li>
                  <li>
                    Optional integrated burglary protection for greater security
                  </li>
                  <li>
                    Easy care thanks to robust and weather resistant surfaces
                  </li>
                  <li>
                    Lasting value thanks to durable quality on the highest level
                  </li>
                  <li>
                    Ultra-modern technology for great handling ease and reliable
                    functionalit
                  </li>
                  <li>
                    All PVC-U profiles are produced with lead-free stabilisers
                    based on calcium and zinc
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={centerSealCover}
              alt="Picture of a project"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img
                  src={centerSeala}
                  alt="image "
                  className="object-cover w-full"
                />
              </div>
              <div class="w-full">
                <img
                  src={centerSealb}
                  alt="image"
                  className="object-cover w-full"
                />
              </div>
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat  md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  WINDOW TYPE
                  <span className="pr-10 block font-normal pb-4">Hinged</span>
                </span>
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  FRAME DEPTH
                  <span className="pr-10 block font-normal pb-4">76 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  GLAZING TYPE
                  <span className="pr-10 block font-normal pb-4">
                    Double - Triple
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">50 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  UF
                  <span className="pr-10 block font-normal pb-4">
                    1.0 W(m²K)
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>

          
        </div>
      </div>
      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-4xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6   bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img
              src={centerSealsec1}
              alt="Team image"
              className="object-cover "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/KOEMMERLING-brochure-Parallel-sliding-tilting-door-23401130251-0117-web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Kömmerling Parallel sliding-tilting door
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/Koe_76_Colours_DC1_-_24_21_12_0152_-_24_21_12_0152_Web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Kömmerling 76 DC1 laminate colour range
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/Koemmerling-ReFrame-brochure-2421130320-0323-web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Kömmerling ReFrame main brochure
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/Koemmerling-Sustainability-brochure-2421990323-0623-web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Main brochure sustainability
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/Koemmerling-Surfaces-and-Colours-brochure-2421120387-0323-web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Kömmerling surfaces and colours
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/Koemmerling-76-centre-seal-brochure-2421130140-0423-web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Kömmerling 76 center seal main brochure
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.koemmerling.com/cms16/files/Koemmerling-76-centre-seal-standard-brochure-2421130141-0623-web.pdf?download=1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Kömmerling 76 center seal standard
          </a>
        </div>
      </div>
    </div>
  );
};

export default Center76;
