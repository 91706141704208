import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";

const NavLinks = ({ closeMobileMenu }) => {
  const [open, setOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  
  return (
    <>
    
      {links.map((link) => (
        <div>
          <div className="px-2 text-left md:cursor-pointer group">
  <h1
    className="py-2 px-3 flex justify-between items-center md:pr-0 pr-2 group transform transition duration-259  hover:text-[#E5B50B]"
    onClick={() => {
      heading !== link.name ? setHeading(link.name) : setHeading("");
      setSubHeading("");
    }}
  >
    {link.name}
    <span className="text-xl md:hidden inline">
      <ion-icon
        name={`${
          heading === link.name ? "chevron-up" : "chevron-down"
        }`}
      ></ion-icon>
    </span>
    <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-1">
      <ion-icon name="chevron-down"></ion-icon>
    </span>
  </h1>
  {link.submenu && (
    <div>
      <div className="absolute top-12 hidden group-hover:md:block hover:md:block">
        <div className="py-3">
          <div
            className="w-4 h-4 left-3 absolute mt-1 bg-white rotate-45"
          ></div>
        </div>
        <div className="bg-white p-2 grid grid-cols-2 gap-2">
          {link.sublinks.map((mysublinks) => (
            <div key={mysublinks.Head}>
              <h1 className="text-md font-normal">{mysublinks.Head}</h1>
              {mysublinks.sublink.map((slink) => (
                <li className="text-sm text-black my-1 transform transition duration-259 hover:text-[#E5B50B]">
                  <Link to={slink.link} className="hover:text-primary">
                    {slink.name}
                  </Link>
                </li>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )}
</div>

          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks) => (
              <div>
                <div>
                  <h1
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                    }
                    className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center md:pr-0 pr-5"
                     >
                    {slinks.Head}

                  </h1>
                  <div
                    className={`${
                      subHeading === slinks.Head ? "md:hidden" : "hidden"
                    }` } 
                  >
                    {slinks.sublink.map((slink) => (
                      <li className="py-3 pl-14 " >
                        <Link to={slink.link} onClick={() => {
                              setOpen(false); // Close the mobile menu when a sublink is clicked
                              closeMobileMenu(); // Call the closeMobileMenu function passed from Navbar
                            }}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
