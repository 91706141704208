import React from "react";
import e75Cover from "../../../assets/e75/e75Cover.webp";
import e75a from "../../../assets/e75/e75a.webp";
import e75b from "../../../assets/e75/e75b.webp";
import e75c from "../../../assets/e75/e75c.webp";
import e75sec1 from "../../../assets/e75/e75sec1.webp";
import e75Tech from "../../../assets/e75/e75Tech.pdf"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const E75 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ETEM E75
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-xl tracking-wide"></h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={e75Cover}
                alt="Picture of a project"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                A heavy duty windows system with high-end structural and energy
                conservation performance. A flat design ensures the system
                caters to modern aesthetic concepts. High performing even in the
                toughest environmental conditions. Specially designed gaskets
                and insulators ensure reliability and energy efficiency. Glazing
                in frame up to 55mm. Possible application of burglar resistant
                hardware up to class RC2.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>BIM Library</li>
                  <li>Thermal break system</li>
                  <li>Modern design</li>
                  <li>Structural stability</li>
                  <li>Safety</li>
                  <li>Quality</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={e75Cover}
              alt="Picture of a project"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      {/* <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img
                  src={e75a}
                  alt="image "
                  className="w-full h-3/5"
                />
              </div>
              <div class="w-full">
                <img
                  src={e75b}
                  alt="image"
                  className="w-full h-3/5"
                />
              </div>
            </div>
          </div> */}

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10  font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4 ">Class 4</span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">
                    CLASS E15000
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  RESISTANCE TO WIND LOAD
                  <span className="pr-10 block font-normal pb-4">CLASS C5</span>
                </span>
                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC2</span>
                </span>

                <span className="pr-10 font-semibold ">
                  THERMAL TRANSMITTANCE
                  <span className="pr-10 block font-normal pb-4">
                    Uf down to 1,26 W/m2.K (for combination of sash and frame)
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              APPLICATIONS
            </h3>
            <p className=" md:pl-32 text-left .leading-8 font-montserrat font-medium  md:text-base tracking-narrow ">
              <ul className="list-disc p-6">
                <li>
                  Windows and doors for both residential and commercial projects
                </li>
                <li>Compatible with every ETEM curtain wall system</li>
              </ul>
            </p>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <p className=" md:pl-32 text-left .leading-8 font-montserrat font-medium  md:text-base tracking-narrow ">
              <ul className="list-disc p-6">
                <li>
                  Single sliding, double sliding, triple sliding, quadruple
                  sliding
                </li>
                <li>Insect screen and sliding shutter incorporated solution</li>
                <li>Special "hotel type" slide, plus fixed typology</li>
              </ul>
            </p>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>

          
        </div>
      </div>
      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-4xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6   bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img src={e75sec1} alt="Team image" className="object-cover " />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href={e75Tech}
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            E75 Technical Catalogue
          </a>
        </div>
      </div>
    </div>
  );
};

export default E75;
