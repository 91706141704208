import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import curtain from "../../assets/curtainwall.webp";
import m7 from "../../assets/alumilm7Passive.webp";
import e85 from "../../assets/eteme85.webp";

const Curtain = () => {
  return (
    <div>
      {" "}
      <h1 className="door-cover text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        CURTAIN WALLS
      </h1>
      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-32 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={curtain}
              alt="Team image"
              className="object-cover w-full md:h-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR CURTAIN WALLS
            </h3>
            {/* SWITCH ORDER OF ALUMIL AND ETEM HIGHLIGHT DIFFERENCES BETWEEN THEM AND SHOWCASE ALUMIL PASSIVE CERTIFICATION */}
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
              Introducing the epitomes of modern architecture: Alumil M7 and
              Etem E85 Curtain Walls. These cutting-edge systems redefine the
              concept of building facades, seamlessly merging design,
              functionality, and energy efficiency. </p>
              <p className=" text-left .leading-8 md:text-base font-montserrat font-medium pt-2 tracking-narrow ">
              Alumil M7 sets new standards in
              sustainable construction with its eco-friendly materials, 
              innovative technology, and passive house certification, guaranteeing optimal thermal performance
              and sound reduction. 
              </p>
              <p className=" text-left .leading-8 md:text-base font-montserrat font-medium pt-2 tracking-narrow ">
              Etem E85 boasts a minimalist
              elegance, enhancing natural light and offering unobstructed views
              while ensuring exceptional thermal insulation and structural
              integrity.  Elevate your buildings with Alumil M7 and Etem E85 Curtain Walls, where
              aesthetics meet sustainability in perfect harmony.
            </p>
          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-center pt-2 pt-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-4xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="m7">
              <img
                src={m7}
                alt="Alumil m7 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SMARTIA M7
            </span>
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="e85">
              <img
                src={e85}
                alt="Etem e85 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ETEM E85
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curtain;
