import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import alumilCover from "../../../assets/alumil/alumilCover.webp";

import s77 from "../../../assets/alumil/s77.webp";
import s77Phos from "../../../assets/alumil/s77Phos.webp";
import s91 from "../../../assets/alumil/s91Passive.webp";

const Alumil = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-7xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-lg md:text-xl tracking-wide">
              LUXURY PERFORMANCE SERIES
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={alumilCover}
                alt="Picture of a project"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                ΑLUMIL has the ideal window & door systems for your home. Our
                wide product range, with multiple hinged, sliding, or bi-folding
                window & door solutions, incorporates all the expertise of our
                innovative Research & Development department and is
                characterized by top aesthetics and excellent functionality.
                Aiming always for top quality, ALUMIL’s energy efficient
                aluminum windows and doors are the first choice for new
                constructions and renovation projects in which compromise is not
                an option.
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={alumilCover}
              alt="Picture of a project"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        SERIES
      </h2>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="s77">
              <img
                src={s77}
                alt="S77 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              S77{" "}
            </span>
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="s77phos">
              <img
                src={s77Phos}
                alt="S77 Phos cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              S77 PHOS
            </span>
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 hover:">
            <Link to="s91">
              <img
                src={s91}
                alt="s91 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              S91
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alumil;
