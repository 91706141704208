import React, { useEffect, useState } from "react";
import "./Home.css";

const IntroAnimation = () => {
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    const intro = document.querySelector(".intro");
    const logoSpan = document.querySelectorAll(".logo2");

    const animateLogo = () => {
      setTimeout(() => {
        logoSpan.forEach((span, idx) => {
          setTimeout(() => {
            span.classList.add("active");
          }, (idx + 1) * 200);
        });

        setTimeout(() => {
          logoSpan.forEach((span, idx) => {
            setTimeout(() => {
              span.classList.remove("active");
              span.classList.add("fade");
            }, (idx + 1) * 50);
          });
        }, 1300);

        setTimeout(() => {
          setAnimationFinished(true);
        }, 3200);

        setTimeout(() => {
          intro.style.top = "-100vh";
        }, 1500);
      });

      sessionStorage.setItem("animationPlayed", "true"); // Store in session storage
    };

    const animationPlayedSessionStorage = sessionStorage.getItem("animationPlayed");

    if (!animationPlayedSessionStorage) {
      animateLogo();
    } else {
      setAnimationFinished(true); // Set animationFinished to true if animation already played during the session
    }
  }, []);

  return (
    <div className={`intro ${animationFinished ? "hidden" : ""}`}>
      <h1 className="logo-header font-suranna text-4xl md:text-10xl font-normal leading-8 tracking-widest uppercase">
        <span className="logo2">V</span>
        <span className="logo2">A</span>
        <span className="logo2">R</span>
        <span className="logo2">S</span>
        <span className="logo2">A</span>
      </h1>
    </div>
  );
};

export default IntroAnimation;
