import React from "react";
import sd95Cover from "../../../assets/sd95/sd95Cover.webp";
import sd95a from "../../../assets/sd95/sd95a.webp";
import sd95b from "../../../assets/sd95/sd95b.webp";
import sd951 from "../../../assets/sd95/sd951.webp";
import sd952 from "../../../assets/sd95/sd952.webp";
import sd953 from "../../../assets/sd95/sd953.webp";
import sd954 from "../../../assets/sd95/sd954.webp";
import sd955 from "../../../assets/sd95/sd955.webp";
import sd956 from "../../../assets/sd95/sd956.webp";
import sd957 from "../../../assets/sd95/sd957.webp";
import sd958 from "../../../assets/sd95/sd958.webp";
import sd95sec1 from "../../../assets/sd95/sd95sec1.webp";
import sd95sec2 from "../../../assets/sd95/sd95sec2.webp";
import sd95sec3 from "../../../assets/sd95/sd95sec3.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sd95 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL SD95
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              SUPREME SD95 insulated system
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={sd95Cover}
                alt="Alumil sd95 cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The system SUPREME SD95 belongs to ALUMIL’s series for entrance
                doors with high quality standards. The system includes a
                complete range of thermal break profiles with flat design for
                totally aligned constructions that offer high functionality.
                Offering many infill options including aluminium or glass
                panels, the SUPREME SD95 is able to meet the most demanding
                architectural trends and aesthetics.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>High energy savings thanks to high energy efficiency</li>
                  <li>High security level</li>
                  <li>Modern design</li>
                  <li>
                    Ease of access; ideal for people with special needs 
                  </li>
                  <li>Exceptional sound insulation</li>
                  <li>Ease of use and high functionality</li>
                  <li>Wide variety of typologies and solutions</li>
                  <li>Exceptional quality with certified performances</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={sd95Cover}
              alt="Alumil sd95 cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-3 gap-2 mx-auto">
              <div class="w-full">
                <img src={sd95a} alt="Alumil sd95 example" />
              </div>
              <div class="w-full ">
                <img src={sd95b} alt="Alumil sd95 example" />
              </div>
              <div class="w-full ">
                <img src={sd95sec3} alt="Alumil sd95 example" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">Yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR SHAPES
                  <span className="pr-10 block font-normal pb-4">Flat</span>
                </span>
                <span className="pr-10 font-semibold ">
                  FRAME DEPTH
                  <span className="pr-10 block font-normal pb-4">95 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  GLAZING TYPE{" "}
                  <span className="pr-10 block font-normal pb-4">
                    Double - Triple
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                    from 24 to 54 mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MECHANISM
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical locking mechanism
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SEALING METHOD
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical at 3 levels with EPDM gaskets
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM THRESHOLD HEIGHT
                  <span className="pr-10 block font-normal pb-4">22 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">
                    Polyamides 34 mm width, ALUMIL Energy bar, Insulation foam
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">CLASS 3A</span>
                </span>
                <span className="pr-10 font-semibold">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                </span>
                <span className="pr-10 font-semibold">
                  WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">C5/B5</span>
                </span>

                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC3</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    up to 180 Kg
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  UF
                  <span className="pr-10 block font-normal pb-4">
                    from 1,2 up to 2,1 W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPES OF TYPOLOGY
                  <span className="pr-10 block font-normal pb-4">
                    For all entrance door typologies with glass or aluminium
                    panels
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className=" grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-center pt-6 ">
              <span className="pr-10 pb-4  ">
                <img
                  src={sd951}
                  alt="Alumil sd95 typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Hinged entrance door
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd952}
                  alt="Alumil sd95 typology"
                  className="object-contain h-24 mx-auto"
                />
                Double leaf entrance door with glazing infill
              </span>
              <span className="pr-10 pb-4   ">
                <img
                  src={sd953}
                  alt="Alumil sd95 typology"
                  className="object-contain h-24 mx-auto"
                />
                Hinged door with insulation panel
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd954}
                  alt="Alumil sd95 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Double leaf door with insulation panel
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd955}
                  alt="Alumil sd95 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door with insulation panel & fixed pane
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd956}
                  alt="Alumil sd95 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door with insulation panel, fixed pane & fanlight
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd957}
                  alt="Alumil sd95 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door with insulation glazing panel & fixed pane
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd958}
                  alt="Alumil sd95 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door with insulation glazing panel, fixed pane and
                fanlight
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
          
        </div>
      </div>

      <div className="  flex flex-col justify-left  pb-2">
        <div className="  md:max-w-7xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center ">
            <img
              src={sd95sec1}
              alt="Alumil sd95 section"
              className="object-cover"
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sd95sec2}
              alt="Alumil sd95 section"
              className="object-cover  "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/supreme-brochure-gr-en.pdf?sfvrsn=5bdefc43_90"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SUPREME Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/sd95_en.pdf?sfvrsn=aa48566a_31"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SD95 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sd95;
