import React from "react";
import e50Cover from "../../../assets/e50/e50Cover.webp";
import e50sec1 from "../../../assets/e50/e50sec1.webp";
import e50sec2 from "../../../assets/e50/e50sec2.webp";
import e50sec3 from "../../../assets/e50/e50sec3.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const E50 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ETEM E50
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Lift & sliding system
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={e50Cover}
                alt="Etem e50 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                One of the most developed, and best optimised, solutions that
                combines both standard slide and Lift & Slide functionality, E50
                is considered by ETEM partners to be best in class. With unique
                capability for such an elegant solution, E50 can accommodate
                sash weight up to 400kg, allowing almost limitless application.
                Thanks to the carefully engineered "Slide plus Fixed" solution,
                the E50 ensures maximum natural interior light, as well as
                reducing construction material costs. The "one frame all around"
                concept ensures fast manufacturing and easy optimisation.
                Maximum 34mm glazing capability, combined with the bearing
                capacity, allows the application of special laminated, tempered
                and thick glass to improve security, soundproofing and
                weatherproofing.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    Ideal for both residential and commercial projects, such as
                    villas, restaurants, pool houses and many more.
                  </li>
                  <li>100% recyclable</li>
                  <li>Modern design</li>
                  <li>Structural stability</li>
                  <li>Safety</li>
                  <li>Energy saving</li>
                  <li>Elegant view</li>
                  <li>Custom solutions</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={e50Cover}
              alt="Etem e50 Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10  font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4 ">Class 4</span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">CLASS 9A</span>
                </span>
                <span className="pr-10 font-semibold ">
                  RESISTANCE TO WIND LOAD
                  <span className="pr-10 block font-normal pb-4">CLASS B2</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SOUND INSULATION
                  <span className="pr-10 block font-normal pb-4">RW=41DB</span>
                </span>

                <span className="pr-10 font-semibold ">
                  THERMAL TRANSMITTANCE
                  <span className="pr-10 block font-normal pb-4">
                    {" "}
                    Uf = 2,04 W/m2.K
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <p className=" md:pl-32 text-left .leading-8 font-montserrat font-medium  md:text-base tracking-narrow ">
              <ul className="list-disc p-6">
                <li>
                  Single sliding, double sliding, triple sliding, quadruple
                  sliding
                </li>
                <li>Insect screen and sliding shutter incorporated solution</li>
                <li>special "hotel type" slide, plus fixed typology</li>
              </ul>
            </p>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-8xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6   bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img
              src={e50sec1}
              alt="Etem e50 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-left  ">
            <img
              src={e50sec2}
              alt="Etem e50 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-left  ">
            <img
              src={e50sec3}
              alt="Etem e50 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://etem.com/sites/default/files/2021-11/ETEM%20-%20technical%20catalogue%20E50.pdf"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            E50 Technical Catalogue
          </a>
        </div>
      </div>

    </div>
  );
};

export default E50;
