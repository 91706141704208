import React from "react";
import { Link } from "react-router-dom"; // Import the Link component

import s700b from "../../../assets/sl60/sl60.webp";
import s700 from "../../../assets/s700/s700.webp";
import e50 from "../../../assets/e50/e50.webp";

const LiftAndSlide = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        LIFT AND SLIDE DOORS
      </h1>

      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-24 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={s700b}
              alt="Doors cover"
              className="object-cover object-right h-full w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR LIFT AND SLIDE DOORS
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Varsa Windows and Doors offer an exceptional solution for large openings with our lift and slide and sliding doors. These doors are perfect for creating a seamless indoor-outdoor living experience with expansive glass panels.



             
            </p>

            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Enhance your space with lift and slide doors that provide superior thermal and sound insulation. Available in double or triple-glazed options, they ensure optimal energy efficiency. Featuring a lift and slide mechanism for added security, our doors come in various finishes and hardware options to match your style.
            </p>

          </div>
        </div>
      </div>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="s700">
              <img
                src={s700}
                alt="Alumil s700 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SUPREME S700
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="e50">
              <img
                src={e50}
                alt="Etem e50 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ETEM E50
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiftAndSlide;
