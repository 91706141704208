import React from "react";
import rehauCover from "../../../assets/rehau/rehauCover.webp";
import geneosec1 from "../../../assets/rehau/geneosec1.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Rehau = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              REHAU GENEO
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              System Geneo tilt-turn windows and doors (UPVC)
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={rehauCover}
                alt="Picture of a project"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                Geneo® is composed of RAU-FIPRO®, a proprietary fiber composite
                material similar to those employed in aeronautic construction
                and racing vehicles for outstanding strength and load
                capacities. In fact, it is the first fully-reinforced polymer
                window profile system that doesn’t rely on steel for its
                strength. RAU-FIPRO, specially developed for Geneo, affords
                window construction benefits only imagined before. Geneo does
                not only provide superior structural integrity but also excels
                when it comes to thermal and acoustical performance. With the
                Geneo design, tilt-turn, hopper and fixed configurations are
                possible. Since the Geneo profile design is fully reinforced
                with fiber composite, the use of steel can be avoided and
                thermal bridges no longer occur. With its center seal and
                optional thermo-modules, Geneo achieves thermal insulation
                values up to the stringent passive house standard.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    Top-Performance Ratings for Thermal Insulation, Sound
                    Insulation and Increased Security
                  </li>
                  <li>
                    RAU-FIPRO profile core for excellent stability and torsional
                    rigidity.
                  </li>
                  <li>
                    Compression-seal technology for superior thermal and
                    acoustical performance.
                  </li>
                  <li>
                    Patented integrated reinforcement system for the highest
                    degree of strength and security.
                  </li>
                  <li>
                    High-definition finish for weather and stain resistance and
                    ease of maintenance.
                  </li>
                  <li>
                    Compatible with REHAU Attivo hardware for superior
                    longevity.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={rehauCover}
              alt="Picture of a project"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10  font-semibold ">
                  NAFS (PG)
                  <span className="pr-10 block font-normal pb-4 ">
                    up to CW-PG70
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  ASTM E90 Acoustical (STC)
                  <span className="pr-10 block font-normal pb-4">
                    up to 45 dB
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  U-value
                  <span className="pr-10 block font-normal pb-4">
                    down to 0.13*
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-4xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6   bg-white ">
          <div className="  md:w-2/3 md:2/2 grid place-items-left  ">
            <img src={geneosec1} alt="Team image" className="object-cover " />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://database.passivehouse.com/en/components/certificate/download/148/en"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            GENEO Passive House Certificate
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://varsa.ca/assets/images/rehau-geneo3.pdf"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            System Geneo tilt-turn, hopper, fixed windows sell sheet
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://varsa.ca/assets/images/rehau-geneo4.doc"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            System Geneo tilt-turn windows specification
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://varsa.ca/assets/images/rehau-geneo5.doc"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            System Geneo fixed windows specification
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://varsa.ca/assets/images/rehau-geneo6.doc"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            System Geneo hinged doors specification
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://varsa.ca/assets/images/rehau-geneo2.pdf"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            System Geneo tilt-turn windows brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Rehau;
