import React from "react";
import s91Cover from "../../../assets/s91/s91Cover.webp";
import s91a from "../../../assets/s91/s91a.webp";
import s91b from "../../../assets/s91/s91b.webp";
import s911 from "../../../assets/s91/s911.webp";
import s912 from "../../../assets/s91/s912.webp";
import s913 from "../../../assets/s91/s913.webp";
import s914 from "../../../assets/s91/s914.webp";
import s915 from "../../../assets/s91/s915.webp";
import s916 from "../../../assets/s91/s916.webp";
import s917 from "../../../assets/s91/s917.webp";

import s91sec1 from "../../../assets/s91/s91sec1.webp";
import s91sec2 from "../../../assets/s91/s91sec2.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const S91 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL S91
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Hinged insulated system SMARTIA s91
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={s91Cover}
                alt="Picture of a project"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The system SMARTIA s91 offers the alternative for frames with
                concealed sash and fittings. This version is ideal for
                maximizing the natural light thanks to its significantly reduced
                visible aluminum face width. The system offers high thermal
                insulation and watertightness performances, which in combination
                with the high sound reduction make it ideal for the most
                demanding projects.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    Highly energy saving thanks to the exceptional thermal
                    performance
                  </li>
                  <li>
                    Enhanced natural light thaks to the system's minimal design
                  </li>
                  <li>Ease of use and high functionality</li>
                  <li>High security level</li>
                  <li>Exceptional sound insulation</li>
                  <li>Modern design</li>
                  <li>
                    Ease of access according to specifications for people with
                    special needs and children
                  </li>
                  <li>Wide variety of typologies and solutions</li>
                  <li>Exceptional quality with certified performances</li>
                  <li>
                    Increased static loads and functionality in case of severe
                    weather conditions
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={s91Cover}
              alt="Picture of a project"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={s91a} alt="image" />
              </div>
              <div class="w-full ">
                <img src={s91b} alt="image" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat  md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  WINDOW TYPE
                  <span className="pr-10 block font-normal pb-4">Hinged</span>
                </span>
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR SHAPES
                  <span className="pr-10 block font-normal pb-4">Flat</span>
                </span>
                <span className="pr-10 font-semibold ">
                  FRAME DEPTH
                  <span className="pr-10 block font-normal pb-4">77 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINIUM FACE HEIGHT
                  <span className="pr-10 block font-normal pb-4">67 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINIUM FACE WIDTH
                  <span className="pr-10 block font-normal pb-4">69 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  GLAZING TYPE
                  <span className="pr-10 block font-normal pb-4">
                    Double - Triple
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                    30 to 45 mm
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MECHANISM
                  <span className="pr-10 block font-normal pb-4">
                    Euro-groove, Euro-nut
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SEALING METHOD
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical at 3 levels with EPDM & foam EPDM gaskets
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MINIMUM THRESHOLD HEIGHT
                  <span className="pr-10 block font-normal pb-4">13 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">
                    Polyamides 40 mm width, ALUMIL Energy bar, Insulation foam
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">
                    CLASS E1500
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                </span>
                <span className="pr-10 font-semibold ">
                  WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">C4/B4</span>
                </span>

                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC3</span>
                </span>

                <span className="pr-10 font-semibold ">
                  UF
                  <span className="pr-10 block font-normal pb-4">
                    from 1,3 up to 2,1 W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    up to 180 Kg
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPES OF TYPOLOGY
                  <span className="pr-10 block font-normal pb-4">
                    For all tilt & turn typologies
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6 ">
              <span className="pr-10 pb-4 ">
                <img
                  src={s911}
                  alt="Picture of a project"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={s912}
                  alt="Picture of a project"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents plus door
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s913}
                  alt="Picture of a project "
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={s914}
                  alt="Picture of a project"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s915}
                  alt="Picture of a project"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with single door
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s916}
                  alt="Picture of a project"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with odd number of vents on both sides
              </span>

              <span className="pr-10 pb-4 ">
                <img
                  src={s917}
                  alt="Picture of a project"
                  className="object-contain h-24 mx-auto "
                />
                Odd number of vents on both sides (meeting stile)
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
          
        </div>
      </div>
      <div className="  flex flex-col justify-left  pb-2">
        <div className="  md:max-w-7xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s91sec1}
              alt="Team image"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s91sec2}
              alt="Team image"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/supreme-brochure-gr-en.pdf?sfvrsn=5bdefc43_90"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SUPREME Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/s91-technical-brochures.pdf?sfvrsn=52f00dc9_43"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S91 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default S91;
