import React from "react";
import sd77Cover from "../../../assets/sd77/sd77Cover2.webp";
import sd77a from "../../../assets/sd77/sd77a.webp";
import sd77b from "../../../assets/sd77/sd77b.webp";
import sd771 from "../../../assets/sd77/sd771.webp";
import sd772 from "../../../assets/sd77/sd772.webp";
import sd773 from "../../../assets/sd77/sd773.webp";
import sd774 from "../../../assets/sd77/sd774.webp";
import sd775 from "../../../assets/sd77/sd775.webp";
import sd776 from "../../../assets/sd77/sd776.webp";
import sd777 from "../../../assets/sd77/sd777.webp";
import sd778 from "../../../assets/sd77/sd778.webp";
import sd77sec1 from "../../../assets/sd77/sd77sec1.webp";
import sd77sec2 from "../../../assets/sd77/sd77sec2.webp";
import sd77sec3 from "../../../assets/sd77/sd77sec3.webp";
import sd77sec4 from "../../../assets/sd77/sd77sec4.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sd77 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL SD77
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              SMARTIA SD77 insulated system
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={sd77Cover}
                alt="Alumil sd77 cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                SMARTIA SD77 is ALUMIL’s system for quality entrance doors
                requiring modern design, high performance and emphasis on
                safety. The system provides the ability to create multiple
                constructions combined either with aluminium panels or with
                glazing infills. Thanks to its modern, flat and coplanar design,
                it offers elegant uniformity that enhances the innovative design
                and aesthetics of the series.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>High energy savings thanks to high energy efficiency</li>
                  <li>High security level</li>
                  <li>Modern design</li>
                  <li>
                    Ease of access, ideal for people with special needs and
                    children
                  </li>
                  <li>Exceptional sound insulation</li>
                  <li>Ease of use and high functionality</li>
                  <li>Wide variety of typologies and solutions</li>
                  <li>Exceptional quality with certified performances</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={sd77Cover}
              alt="Alumil sd77 cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-3 gap-2 mx-auto">
              <div class="w-full">
                <img src={sd77a} alt="Alumil sd77 example" />
              </div>
              <div class="w-full ">
                <img src={sd77b} alt="Alumil sd77 example" />
              </div>
              <div class="w-full ">
                <img src={sd77sec4} alt="Alumil sd77 example" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">Yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR SHAPES
                  <span className="pr-10 block font-normal pb-4">Flat</span>
                </span>
                <span className="pr-10 font-semibold ">
                  FRAME DEPTH
                  <span className="pr-10 block font-normal pb-4">77 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  GLAZING TYPE{" "}
                  <span className="pr-10 block font-normal pb-4">
                    Double - Triple
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                    from 23 to 59 mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MECHANISM
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical locking mechanism
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SEALING METHOD
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical at 3 levels with EPDM gaskets
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM THRESHOLD HEIGHT
                  <span className="pr-10 block font-normal pb-4">22 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">
                    Polyamides 40 mm width, ALUMIL Energy bar, Insulation foam
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  UW
                  <span className="pr-10 block font-normal pb-4">
                    from 0,7 W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">CLASS 7A</span>
                </span>
                <span className="pr-10 font-semibold">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                </span>
                <span className="pr-10 font-semibold">
                  WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">C4/B4</span>
                </span>

                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC2</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    up to 180 Kg
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  UF
                  <span className="pr-10 block font-normal pb-4">
                    from 0,9 up to 1.9 W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINIUM FACE HEIGHT
                  <span className="pr-10 block font-normal pb-4">117 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPES OF TYPOLOGY
                  <span className="pr-10 block font-normal pb-4">
                    For all entrance door typologies with glass or aluminium
                    panels
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className=" grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-center pt-6 ">
              <span className="pr-10 pb-4  ">
                <img
                  src={sd771}
                  alt="Alumil sd77 typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Hinged door inwards/outwards
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd772}
                  alt="Alumil sd77 typology"
                  className="object-contain h-24 mx-auto"
                />
                Double leaf door inwards/outwards opening
              </span>
              <span className="pr-10 pb-4   ">
                <img
                  src={sd773}
                  alt="Alumil sd77 typology"
                  className="object-contain h-24 mx-auto"
                />
                Hinged door with insulation panel 04
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd774}
                  alt="Alumil sd77 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Double leaf door with insulation panel
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd775}
                  alt="Alumil sd77 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door with insulation panel & fixed pane
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd776}
                  alt="Alumil sd77 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door with insulation panel, fixed pane & fanlight
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd777}
                  alt="Alumil sd77 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door inwards/outwards with insulation panel & fixed pane
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd778}
                  alt="Alumil sd77 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Hinged door inwards/outwards with insulation panel, fixed pane &
                fanlight
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
         
        </div>
      </div>

      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-8xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center ">
            <img
              src={sd77sec1}
              alt="Alumil sd77 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sd77sec2}
              alt="Alumil sd77 section"
              className="object-cover  "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sd77sec3}
              alt="Alumil sd77 section"
              className="object-cover  "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/smartia-brochure-gr-en.pdf?sfvrsn=2dc3db89_78"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SMARTIA Aluminum Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/pre-qualification-sd77f4e3a81aba954c1b8c233f00e0788cd4.pdf?sfvrsn=2ebdd2d8_31"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SD77 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/smartia-sd77-en.pdf?sfvrsn=ead2c007_28"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SD77 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sd77;
