import React from "react";
import s650Cover from "../../../assets/s650/s650Cover.webp";
import s650a from "../../../assets/s650/s650a.webp";
import s650b from "../../../assets/s650/s650b.webp";
import s650c from "../../../assets/s650/s650c.webp";
import s650d from "../../../assets/s650/s650d.webp";
import s6501 from "../../../assets/s650/s6501.webp";
import s6502 from "../../../assets/s650/s6502.webp";
import s6503 from "../../../assets/s650/s6503.webp";
import s6504 from "../../../assets/s650/s6504.webp";
import s6505 from "../../../assets/s650/s6505.webp";
import s6506 from "../../../assets/s650/s6506.webp";
import s6507 from "../../../assets/s650/s6507.webp";
import s6508 from "../../../assets/s650/s6508.webp";
import s650sec1 from "../../../assets/s650/s650sec1.webp";
import s650sec2 from "../../../assets/s650/s650sec2.webp";
import s650sec3 from "../../../assets/s650/s650sec3.webp";
import s650sec4 from "../../../assets/s650/s650sec4.webp";
import s650sec5 from "../../../assets/s650/s650sec5.webp";
import s650sec6 from "../../../assets/s650/s650sec6.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const S650 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL S650
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Minimal Sliding insulated system SUPREME S650 PHOS
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={s650Cover}
                alt="Alumil s650 cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The thermally insulated sliding system SUPREME S650 PHOS is the
                ideal solution for projects requiring wide spans with minimal
                aluminium sight lines. Massive yet elegant glazing surfaces are
                achieved while maintaining high functionality, performances and
                minimal architectural design.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    Enhanced natural light thanks to the system's minimal design
                    and the wide glazing surfaces
                  </li>
                  <li>
                    High energy savings thanks to exceptional energy efficiency
                  </li>
                  <li>High-security level</li>
                  <li>Exceptional sound insulation</li>
                  <li>Maximum ease of use and high functionality</li>
                  <li>
                    Available in three alternative solutions for the on-floor
                    installation, in-floor installation or totally concealed
                    in-floor installation covered with the floor finish (version
                    SUPREME S650 PHOS Eclipse)
                  </li>
                  <li>
                    Alternative version for automated sliding sashes (SUPREME
                    S650 e-ΜΟΤΙΟΝ)
                  </li>
                  <li>
                    Ease of access according to specifications for people with
                    special needs and children
                  </li>
                  <li>Wide variety of typologies</li>
                  <li>Ideal for wide spans</li>
                  <li>Exceptional quality with certified performances</li>
                  <li>
                    Increased static loads and functionality in case of severe
                    weather conditions
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={s650Cover}
              alt="Alumil s650 cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={s650a} alt="Alumil s650 examples" />
              </div>
              <div class="w-full ">
                <img src={s650b} alt="Alumil s650 examples" />
              </div>
              <div class="w-full ">
                <img src={s650c} alt="Alumil s650 examples" />
              </div>
              <div class="w-full ">
                <img src={s650d} alt="Alumil s650 examples" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10  font-semibold ">
                  WINDOW TYPES
                  <span className="pr-10 block font-normal pb-4 ">Sliding</span>
                </span>
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">Yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR SHAPES
                  <span className="pr-10 block font-normal pb-4">Flat</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH DEPTH
                  <span className="pr-10 block font-normal pb-4">62mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINUM FACE HEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    Concealed Profiles
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINIUM FACE WIDTH
                  <span className="pr-10 block font-normal pb-4">25 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  GLAZING TYPE{" "}
                  <span className="pr-10 block font-normal pb-4">
                    Double - Triple
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">46 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  MECHANISM
                  <span className="pr-10 block font-normal pb-4">Alumil</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SEALING METHOD
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical with tri-fin brushes
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM THRESHOLD HEIGHT
                  <span className="pr-10 block font-normal pb-4">35 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">
                    Polyamides, PVC
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  UW
                  <span className="pr-10 block font-normal pb-4">
                    1,1 W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">CLASS 9A</span>
                </span>
                <span className="pr-10 font-semibold">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                </span>
                <span className="pr-10 font-semibold">
                  WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">C5</span>
                </span>

                <span className="pr-10 font-semibold ">
                  SOUND REDUCTION
                  <span className="pr-10 block font-normal pb-4">46 dB</span>
                </span>
                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC2</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    up to 1000 Kg
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className=" grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-center pt-6 ">
              <span className="pr-10 pb-4  ">
                <img
                  src={s6501}
                  alt="Alumil s650 typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Double horizontal sliding sash
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s6502}
                  alt="Alumil s650 typology"
                  className="object-contain h-24 mx-auto"
                />
                Single sliding sash with fixed light
              </span>
              <span className="pr-10 pb-4   ">
                <img
                  src={s6503}
                  alt="Alumil s650 typology"
                  className="object-contain h-24 mx-auto"
                />
                Four horizontal sliding sashes
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s6504}
                  alt="Alumil s650 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Double horizontal sliding sash meeting stile with fixed lights
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s6505}
                  alt="Alumil s650 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Corner typology window, with horizontal parallel sliding sashes
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s6506}
                  alt="Alumil s650 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Corner typology with two sliding sashes and fixed lights
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s6507}
                  alt="Alumil s650 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Triple horizontal sliding sash
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s6508}
                  alt="Alumil s650 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Four horizontal sliding sashes
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
          
        </div>
      </div>
      <div className="  flex flex-col justify-center  pb-2">
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s650sec1}
              alt="Alumil s650 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s650sec2}
              alt="Alumil s650 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s650sec3}
              alt="Alumil s650 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s650sec4}
              alt="Alumil s650 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s650sec5}
              alt="Alumil s650 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s650sec6}
              alt="Alumil s650 sections"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/supreme-brochure-gr-en.pdf?sfvrsn=5bdefc43_90"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SUPREME Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/preq-supreme-s650.pdf?sfvrsn=dab5ad74_32"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S650 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/s650-technical-catalogue.pdf?sfvrsn=dd1a8496_39"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S650 Brochure
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/smartia/preq-ifs-en.pdf?sfvrsn=60f3c181_3"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            IFS System specs
          </a>
        </div>
      </div>
    </div>
  );
};

export default S650;
