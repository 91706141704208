import React from "react";
import s700Cover from "../../../assets/s700/s700Cover.webp";
import s700a from "../../../assets/s700/s700a.webp";
import s700b from "../../../assets/s700/s700b.webp";
import s700c from "../../../assets/s700/s700c.webp";
import s700d from "../../../assets/s700/s700d.webp";
import s7001 from "../../../assets/s700/s7001.webp";
import s7002 from "../../../assets/s700/s7002.webp";
import s7003 from "../../../assets/s700/s7003.webp";
import s7004 from "../../../assets/s700/s7004.webp";
import s7005 from "../../../assets/s700/s7005.webp";
import s7006 from "../../../assets/s700/s7006.webp";
import s7007 from "../../../assets/s700/s7007.webp";
import s7008 from "../../../assets/s700/s7008.webp";
import s7009 from "../../../assets/s700/s7009.webp";
import s70010 from "../../../assets/s700/s70010.webp";
import s70011 from "../../../assets/s700/s70011.webp";
import s70012 from "../../../assets/s700/s70012.webp";
import s70013 from "../../../assets/s700/s70013.webp";
import s70014 from "../../../assets/s700/s70014.webp";
import s700sec1 from "../../../assets/s700/s700sec1.webp";
import s700sec2 from "../../../assets/s700/s700sec2.webp";
import s700sec3 from "../../../assets/s700/s700sec3.webp";
import s700sec4 from "../../../assets/s700/s700sec4.webp";
import s700sec5 from "../../../assets/s700/s700sec5.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const S700 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL S700
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Lift & slide insulated system SUPREME S700
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={s700Cover}
                alt="Alumil s700 cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The lift & slide system SUPREME S700 represents the new
                generation of ALUMIL’s high-end systems. It is an excellent
                solution for projects with demanding requirements, meeting the
                trend of “transparent” architecture through extensive glass
                surfaces, with high thermal insulation, safety and modern
                design. Its minimal design and its various innovative
                characteristics, make it the ideal option for projects requiring
                wide spans for enhanced daylight, outstanding performance and
                maximum ease of use.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    Maximum ease of use and high functionality, optionally
                    combined with automatic motion
                  </li>
                  <li>
                    Enhanced natural light thanks to the wide glazing surfaces
                  </li>
                  <li>
                    High energy savings thanks to the exceptional energy
                    efficency
                  </li>
                  <li>High security level</li>
                  <li>Exceptional sound insulation</li>
                  <li>Ease of access</li>
                  <li>Wide variety of typologies and solutions</li>
                  <li>Exceptional quality with certified performances</li>
                  <li>
                    Increased static loads and functionality in case of severe
                    weather conditions
                  </li>
                  <li>
                    Available solution for totally concealed bottom frame
                    installation covered with the floor finish (version SUPREME
                    S700 Eclipse)
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={s700Cover}
              alt="Alumil s700 cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={s700a} alt="Alumil s700 example" />
              </div>
              <div class="w-full ">
                <img src={s700b} alt="Alumil s700 example" />
              </div>
              <div class="w-full ">
                <img src={s700c} alt="Alumil s700 example" />
              </div>
              <div class="w-full ">
                <img src={s700d} alt="Alumil s700 example" />
              </div>
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10  font-semibold ">
                  WINDOW TYPES
                  <span className="pr-10 block font-normal pb-4 ">
                    Lift & Sliding
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">Yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR SHAPES
                  <span className="pr-10 block font-normal pb-4">Flat</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH DEPTH
                  <span className="pr-10 block font-normal pb-4">70 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINUM FACE HEIGHT
                  <span className="pr-10 block font-normal pb-4">91 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINIUM FACE WIDTH
                  <span className="pr-10 block font-normal pb-4">47 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  GLAZING TYPE{" "}
                  <span className="pr-10 block font-normal pb-4">
                    Double - Triple
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                    from 22 to 50 mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MECHANISM
                  <span className="pr-10 block font-normal pb-4">
                    Lift & slide mechanism
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SEALING METHOD
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical with EPDM gaskets & tri-fin brushes
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM THRESHOLD HEIGHT
                  <span className="pr-10 block font-normal pb-4">24 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">
                    Polyamides, ALUMIL Energy bar, Insulation foam, PVC
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  UW
                  <span className="pr-10 block font-normal pb-4">
                    Uw=1,1W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">
                    CLASS E900
                  </span>
                </span>
                <span className="pr-10 font-semibold">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                </span>
                <span className="pr-10 font-semibold">
                  WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">C4/B4</span>
                </span>

                <span className="pr-10 font-semibold ">
                  SOUND REDUCTION
                  <span className="pr-10 block font-normal pb-4">41 dB</span>
                </span>
                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC2</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    up to 600 Kg
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className=" grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-center pt-6 ">
              <span className="pr-10 pb-4  ">
                <img
                  src={s7001}
                  alt="Alumil s700 typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Double lifting sliding sash
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7002}
                  alt="Alumil s700 typology"
                  className="object-contain h-24 mx-auto"
                />
                Double lifting sliding sash with narrow interlocking profile{" "}
              </span>
              <span className="pr-10 pb-4   ">
                <img
                  src={s7003}
                  alt="Alumil s700 typology"
                  className="object-contain h-24 mx-auto"
                />
                Four lifting sliding sashes meeting stile{" "}
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7004}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Four lifting sliding sashes meeting stile with narrow
                interlocking profile{" "}
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7005}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Corner typology with four lifting sliding sashes{" "}
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7006}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Triple lifting sliding sash{" "}
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7007}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Six lifting sliding sashes with meeting stile
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7008}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Single lifting sliding pocket sash with fly-screen & shutter
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s7009}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Double lifting sliding pocket sash with meeting stile, with
                fly-screen & shutter
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s70010}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Single lifting sliding sash with fixed light
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s70011}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Two lifting sliding sashes with fixed light
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s70012}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Single lifting sliding sash with two fixed lights
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s70013}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Double lifting sliding sash with meeting stile & with two fixed
                lights
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s70014}
                  alt="Alumil s700 typology"
                  className="object-contain   h-24 mx-auto"
                />
                Double lifting sliding sash with fly-screen
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
          
        </div>
      </div>
      <div className="  flex flex-col justify-center  pb-2">
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s700sec1}
              alt="Alumil s700 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s700sec2}
              alt="Alumil s700 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s700sec3}
              alt="Alumil s700 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s700sec4}
              alt="Alumil s700 sections"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s700sec5}
              alt="Alumil s700 sections"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/supreme-brochure-gr-en.pdf?sfvrsn=5bdefc43_90"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SUPREME Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/pre-qualification-s70039f086a1742c490dbdc0c64b3b9b2a75.pdf?sfvrsn=8486e5ae_19"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SF700 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/s700_en.pdf?sfvrsn=7d3016c6_32"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S700 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default S700;
