import React from "react";
import m7Cover from "../.././assets/m7/m7Cover.webp";
import m7a from "../.././assets/m7/m7a.webp";
import m7c from "../.././assets/m7/m7c.webp";
import m71 from "../.././assets/m7/m71.webp";
import m72 from "../.././assets/m7/m72.webp";
import m73 from "../.././assets/m7/m73.webp";
import m74 from "../.././assets/m7/m74.webp";
import m75 from "../.././assets/m7/m75.webp";
import m76 from "../.././assets/m7/m76.webp";

import m7sec1 from "../.././assets/m7/m7sec1.webp";
import m7sec2 from "../.././assets/m7/m7sec2.webp";
import m7sec3 from "../.././assets/m7/m7sec3.webp";
import m7sec4 from "../.././assets/m7/m7sec4.webp";
import m7sec5 from "../.././assets/m7/m7sec5.webp";
import m7sec6 from "../.././assets/m7/m7sec6.webp";
import m7sec7 from "../.././assets/m7/m7sec7.webp";
import m7sec8 from "../.././assets/m7/m7sec8.webp";
import m7sec9 from "../.././assets/m7/m7sec9.webp";
import m7sec10 from "../.././assets/m7/m7sec10.webp";
import m7sec11 from "../.././assets/m7/m7sec11.webp";
import m7sec12 from "../.././assets/m7/m7sec12.webp";
import m7sec13 from "../.././assets/m7/m7sec13.webp";
import m7sec14 from "../.././assets/m7/m7sec14.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const M7 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL M7
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text-lg pb-3 text-md md:text-xl tracking-wide">
              High-performance Curtain Wall system SMARTIA M7
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={m7Cover}
                alt="Alumil m7 cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                SMARTIA M7 is a complete system for high energy efficiency
                curtain walls, which offers quality constructions in affordable
                prices and fully meets all stability and safety requirements.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>High Energy Efficiency</li>
                  <li>Increased Comfort level</li>
                  <li>Increased of security level</li>
                  <li>Increased of sound insulation level</li>
                  <li>Maximum functionality</li>
                  <li>Enhancement of Natural Light</li>
                  <li>
                    Guaranteed performance quality in terms of air- water
                    permeability, wind pressure as well as the Uf.
                  </li>
                  <li>
                    Increased static and functionality in extreme natural
                    phenomena
                  </li>
                  <li>Flexibility in construction</li>
                  <li>
                    Vast alternatives in custom solutions tailor made to the
                    building
                  </li>
                  <li>Ease of use with electric motion</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={m7Cover}
              alt="Alumil m7 cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">
        
      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={m7a} alt="Alumil m7 example" />
              </div>
              <div class="w-full ">
                <img src={m7c} alt="Alumil m7 example" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat  md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  AESTHETICS
                  <span className="pr-10 block font-normal pb-4">Standard</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR VISIBLE WIDTH
                  <span className="pr-10 block font-normal pb-4">50 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SECURITY OF VISIBLE LOAD
                  <span className="pr-10 block font-normal pb-4">
                    Design Load ± 2 KN/m2
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">RE 1200</span>
                </span>
                <span className="pr-10 font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">AE</span>
                </span>
                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC3/RC4</span>
                </span>

                <span className="pr-10 font-semibold ">
                  TYPE OF HOLDING GLAZING
                  <span className="pr-10 block font-normal pb-4">
                    Pressure Plate or Structural Silicon
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  UF
                  <span className="pr-10 block font-normal pb-4">
                    from 1,0 up to 2,3 W/m²K
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MULLION DEPTH
                  <span className="pr-10 block font-normal pb-4">
                    28 up to 250 mm
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF INSTALLATION
                  <span className="pr-10 block font-normal pb-4">Standard</span>
                </span>

                <span className="pr-10 font-semibold ">
                  TRANSOMS MAX INERTIA
                  <span className="pr-10 block font-normal pb-4">973</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPES OF TYPOLOGY
                  <span className="pr-10 block font-normal pb-4">
                    Fixed, Projected, Parallel Projected
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM WEIGHT
                  <span className="pr-10 block font-normal pb-4">180 Kg</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TRANSOM DEPTH
                  <span className="pr-10 block font-normal pb-4">
                    16,5 up to 257,5 mm
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MULLION MAX INERTIA
                  <span className="pr-10 block font-normal pb-4">2788</span>
                </span>

                <span className="pr-10 font-semibold ">
                  TYPES OF TYPOLOGY
                  <span className="pr-10 block font-normal pb-4">
                    For all tilt & turn typologies, pivot windows
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6 ">
              <span className="pr-10 pb-4 ">
                <img
                  src={m71}
                  alt="Alumil m7 typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Tilt and turn window
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={m72}
                  alt="Alumil m7 typology"
                  className="object-contain h-24  mx-auto"
                />
                Parallel and projected outward window
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={m73}
                  alt="Alumil m7 typology"
                  className="object-contain h-24  mx-auto"
                />
                Hinged opening outward door
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={m74}
                  alt="Alumil m7 typology"
                  className="object-contain h-24  mx-auto"
                />
                Hinged opening inward door
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={m75}
                  alt="Alumil m7 typology"
                  className="object-contain h-24  mx-auto"
                />
                Projected outward window
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={m76}
                  alt="Alumil m7 typology"
                  className="object-contain h-24  mx-auto"
                />
                Parallel projected outward window
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
          
        </div>
      </div>
      <div className="  flex flex-col justify-center  pb-2">
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec1}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec2}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec3}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec4}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec5}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec6}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec7}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec8}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec9}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec10}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec11}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec12}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec13}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={m7sec14}
              alt="Alumil m7 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>
      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/smartia-brochure-gr-en.pdf?sfvrsn=2dc3db89_78"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SMARTIA Aluminum Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/smartia/m7_end34db9470c47453cb464ae21b7ec7c15.pdf?sfvrsn=c0838c5f_64"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            M7 Brochure
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/smartia/preq-fire-rated-systems_en_2022_preview-1.pdf?sfvrsn=40a48113_1"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Fire Rated Systems
          </a>
        </div>
      </div>
    </div>
  );
};

export default M7;
