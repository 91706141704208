import React from "react";
import sd115Cover from "../../../assets/sd115/sd115Main.webp";
import sd115a from "../../../assets/sd115/sd115a.webp";
import sd115b from "../../../assets/sd115/sd115b.webp";
import sd115c from "../../../assets/sd115/sd115c.webp";
import sd1151 from "../../../assets/sd115/sd1151.webp";
import sd1152 from "../../../assets/sd115/sd1152.webp";
import sd1153 from "../../../assets/sd115/sd1153.webp";

import sd115sec1 from "../../../assets/sd115/sd115sec1.webp";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sd115 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL SD115
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              SUPREME SD115 insulated system
            </h3>

            

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={sd115Cover}
                alt="Alumil sd95 cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
              ALUMIL creates new horizons for modern residential and industrial design thanks to the innovative design of the system SUPREME SD115 Pivot that offers extremely large entrance doors for an exceptional aesthetic result with a pure, minimalistic design and coplanar surfaces.

The system SUPREME SD115 Pivot provides an outstanding new feeling of brightness and connection between internal and external environments, rendering the system unique in concept and design.

The innovative design of the system, alongside the state-of-the-art equipment, provide the prerequisites for prestigious entrance doors with high aesthetics, exceptional quality and outstanding performances in terms of security, insulation, and functionality.

              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>High security level</li>
                  <li>Modern design</li>
                  <li>
                  Maximum energy saving thanks to the exceptional thermal
                  </li>
                  <li>Ease of access ideal for people with disabilities and children</li>
                  <li>Exceptional sound insulation</li>
                  <li>Ease of use and high functionality</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={sd115Cover}
              alt="Alumil sd115 cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-3 gap-2 mx-auto">
              <div class="w-full">
                <img src={sd115a} alt="Alumil sd95 example" />
              </div>
              <div class="w-full ">
                <img src={sd115b} alt="Alumil sd95 example" />
              </div>
              <div class="w-full ">
                <img src={sd115c} alt="Alumil sd95 example" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">Yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  EXTERIOR SHAPES
                  <span className="pr-10 block font-normal pb-4">Flat</span>
                </span>
                <span className="pr-10 font-semibold ">
                  FRAME DEPTH
                  <span className="pr-10 block font-normal pb-4">115 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  SASH DEPTH
                  <span className="pr-10 block font-normal pb-4">115 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">250 Kg</span>
                </span>
                
                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINUM FACE HEIGHT
                  <span className="pr-10 block font-normal pb-4">
                  70 mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                    115 mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MECHANISM
                  <span className="pr-10 block font-normal pb-4">
                    Multiple points lock
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SEALING METHOD
                  <span className="pr-10 block font-normal pb-4">
                    Perimetrical at 3 levels with EPDM gaskets
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MINIMUM THRESHOLD HEIGHT
                  <span className="pr-10 block font-normal pb-4">7 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  TYPE OF THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">
                    Polyamides 34 mm width, ALUMIL Energy bar, Insulation foam
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC3</span>
                </span>
                <span className="pr-10 font-semibold ">
                  UF
                  <span className="pr-10 block font-normal pb-4">
                  from 0,75 W/m²K
                  </span>
                </span>
            
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className=" grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-center pt-6 ">
              <span className="pr-10 pb-4  ">
                <img
                  src={sd1151}
                  alt="Alumil sd115 typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Hinged door with insulation panel
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sd1152}
                  alt="Alumil sd115 typology"
                  className="object-contain h-24 mx-auto"
                />
                Hinged door with insulation panel & fixed panel
              </span>
              <span className="pr-10 pb-4   ">
                <img
                  src={sd1153}
                  alt="Alumil sd115 typology"
                  className="object-contain h-24 mx-auto"
                />
               Hinged door with insulation panel, fixed pane & fanlight
              </span>
              
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
          
        </div>
      </div>

      <div className="  flex flex-col justify-left  pb-2">
        <div className="  md:max-w-3xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center ">
            <img
              src={sd115sec1}
              alt="Alumil sd115 section"
              className="object-cover"
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/sd115-technical-brochures.pdf?sfvrsn=ca3ecbb9_19"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SD115 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sd115;
