import React from "react";
import sf85Cover from "../../../assets/sf85/sf85Cover.webp";
import sf85a from "../../../assets/sf85/sf85a.webp";
import sf85b from "../../../assets/sf85/sf85b.webp";
import sf85c from "../../../assets/sf85/sf85c.webp";
import sf85d from "../../../assets/sf85/sf85d.webp";
import sf85e from "../../../assets/sf85/sf85e.webp";
import sf85f from "../../../assets/sf85/sf85f.webp";
import sf85g from "../../../assets/sf85/sf85g.webp";
import sf851 from "../../../assets/sf85/sf851.webp";
import sf852 from "../../../assets/sf85/sf852.webp";
import sf853 from "../../../assets/sf85/sf853.webp";
import sf854 from "../../../assets/sf85/sf854.webp";
import sf855 from "../../../assets/sf85/sf855.webp";
import sf856 from "../../../assets/sf85/sf856.webp";
import sf857 from "../../../assets/sf85/sf857.webp";
import sf858 from "../../../assets/sf85/sf858.webp";
import sf859 from "../../../assets/sf85/sf859.webp";
import sf8510 from "../../../assets/sf85/sf8510.webp";
import sf85sec1 from "../../../assets/sf85/sf85sec1.webp";
import sf85sec2 from "../../../assets/sf85/sf85sec2.webp";
import sf85sec3 from "../../../assets/sf85/sf85sec3.webp";
import sf85sec4 from "../../../assets/sf85/sf85sec4.webp";
import sf85sec5 from "../../../assets/sf85/sf85sec5.webp";
import sf85sec6 from "../../../assets/sf85/sf85sec6.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sf85 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL SF85
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center pb-3 text-md md:text-lg tracking-wide">
              SUPREME SF85 Insulated Folding Doors
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={sf85Cover}
                alt="Alumil sf85 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                A world-class highly insulated folding door system with
                bottom-slide operation, combining minimal aesthetics with
                outstanding performance, very large dimensions with literally
                low sight lines, enhanced burglar protection with a very large
                variety of solutions.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                    Very large dimension in terms of height (up to 4.0m) and vent
                    width (up to 1.5m)
                  </li>
                  <li>
                    Amazingly reduced sight lines which contributes to the
                    overall minimal design
                  </li>
                  <li>
                    Extensive variety of solutions, with odd and even number of
                    vents, corner construction, semi-structural,
                    inwards and outwards opening
                  </li>
                  <li>
                    Extreme performance in terms of air permeability, wind load
                    resistance and water tightness
                  </li>
                  <li>
                    Significant energy and cost savings due to the excellent
                    thermal insulation and U-values (as low as 1.0 W/m2K)
                  </li>
                  <li>
                    Enhanced levels of security and burglar protection, meeting
                    all sector security standards
                  </li>
                  <li>
                    Smooth operation and long-lasting flawless functionality
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={sf85Cover}
              alt="Alumil sf85 Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={sf85a} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={sf85b} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={sf85c} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={sf85d} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={sf85e} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={sf85f} alt="Alumil sf85 example" />
              </div>
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat  md:text-base text-left pt-6 uppercase ">
               
                <span className="pr-10 font-semibold ">
                  THERMAL INSULATION
                  <span className="pr-10 block font-normal pb-4">yes</span>
                </span>
                <span className="pr-10 font-semibold ">
                  AESTHETICS
                  <span className="pr-10 block font-normal pb-4">minimal</span>
                </span>
                <span className="pr-10 font-semibold ">
                  OPERATION{" "}
                  <span className="pr-10 block font-normal pb-4">
                    bottom slide
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  PANEL{" "}
                  <span className="pr-10 block font-normal pb-4">
                    aluminium framed panel, semi-structural panel
                  </span>
                </span>
                

                <span className="pr-10 font-semibold ">
                  APPLICATIONS
                  <span className="pr-10 block font-normal pb-4">
                    residential with 1.5m panel width, commercial with 4.0m
                    panel height, high-end projects
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  SASH WIDTH
                  <span className="pr-10 block font-normal pb-4">85 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  GLAZING
                  <span className="pr-10 block font-normal pb-4">21-58mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                  POLYAMIDE
                  <span className="pr-10 block font-normal pb-4">
                    Double 40mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  EXTRA INSULATING FITTINGS
                  <span className="pr-10 block font-normal pb-4">
                    Alumil energy bar insulation and PE insulation foam
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MAIN GASKET
                  <span className="pr-10 block font-normal pb-4">
                    Single from co-extruded EPDM and foam
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  LOCKING
                  <span className="pr-10 block font-normal pb-4">
                    Quadruple locking latches
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  VISIBLE ALUMINIUM
                  <span className="pr-10 block font-normal pb-4">105mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  MAXIMUM WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    200Kg/ vent
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MAXIMUM HEIGHT
                  <span className="pr-10 block font-normal pb-4">4,0 m</span>
                </span>
                <span className="pr-10 font-semibold ">
                  MAXIMUM PANEL WIDTH
                  <span className="pr-10 block font-normal pb-4">1,5 m</span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">
                    7A/9Α (with subsill), IFT
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">
                    class 4, IFT
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">
                    class C3/B3, IFT
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE{" "}
                  <span className="pr-10 block font-normal pb-4">
                    RC2 IFT & PAS24 (UK)
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  THERMAL PERFORMANCE
                  <span className="pr-10 block font-normal pb-4">
                    1.1 W/m²K, IFT
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6 ">
              <span className="pr-10 pb-4 ">
                <img
                  src={sf851}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={sf852}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents plus door
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sf853}
                  alt="Alumil sf85 Typology "
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf854}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={sf855}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with single door
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={sf856}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with odd number of vents on both sides
              </span>

              <span className="pr-10 pb-4 ">
                <img
                  src={sf857}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24 mx-auto "
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sf858}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={sf859}
                  alt="Alumil sf85 Typology "
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents plus door
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf8510}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf8510}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Even & odd number of vents on both sides (meeting stile)
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
         
        </div>
      </div>
      <div className="  flex flex-col justify-center  pb-2">
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec1}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec2}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec3}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec4}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec5}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:w-2/2 grid place-items-center  ">
            <img
              src={sf85sec6}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/supreme-brochure-gr-en.pdf?sfvrsn=5bdefc43_90"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SUPREME Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/supreme-sf85.pdf?sfvrsn=a54b85df_21"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SF85 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/sf85_en.pdf?sfvrsn=4705adb6_35"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SF85 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sf85;
