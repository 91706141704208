import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default class Testimonials extends Component {
    render() {
      return (
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
        


          <div className="">
           
            <div className="myCarousel">
              <h3 className="pt-5">Felipe Freig</h3>
              <h4>Versa Homes</h4>
              <p>
              I am pleased to provide a glowing testimonial for Varsa Windows. As a luxury custom home builder in Langley, BC, I have consistently relied on Varsa Windows to enhance the quality and aesthetics of the homes we build for our discerning clients. Their commitment to excellence, impeccable craftsmanship, and attention to detail make them an invaluable partner in our projects. Varsa Windows consistently delivers top-notch, high-end windows that not only elevate the overall look of our custom homes but also contribute to energy efficiency and durability. I highly recommend Varsa Windows to fellow builders and homeowners who demand nothing but the best. Their windows truly exemplify the elegance and quality we seek in our luxury custom homes.
              </p>
            </div>
          </div>

          <div className="">
           
           <div className="myCarousel">
             <h3 className="pt-5">Frank Geier</h3>
             <h4>Northland Properties</h4>
             <p>
             Varsa played a critical role for our contemporary home design and wanted to make sure the windows would fit our requirements. George and his team did not disappoint, they were responsive and flexible to ensure the design intent was met which we greatly appreciated. I felt so good about the Varsa experience we used their product at a 300 room hotel renovation where all the windows for the building needed to be replaced. Great people to work with! 
             </p>
           </div>
         </div>
  
          <div className="">
         
            <div className="myCarousel">
              <h3 className="pt-5">Donato Centanni</h3>
              <h4>Client</h4>
              <p>
              I've been working with George and the team at Varsa for 3 years. Like anyone else, building a house comes  with making alot of decisions. George knew what I wanted, provided samples and made my choice clear.  I'm so happy with Varsa that I recommend friends daily.  Thank you for making my progress and project that much better. 

              </p>
            </div>
          </div>
  
          <div className="">
           
            <div className="myCarousel">
              <h3 className="pt-5">Adam lee</h3>
              <h4>Belican Construction Ltd.</h4>
              <p>
              I couldn't be more thrilled with the exceptional service and top-quality windows and doors provide by Varsa. From the moment I reached out for a quote, their team was nothing short of professional and attentive. The installation process was seamless, and the results exceeded my expectations. The windows not only enhance the aesthetic appeal of my home but also significantly improve energy efficiency. I highly recommend Varsa windows and doors to anyone in need of windows. They've truly set the gold standard in the industry.
              </p>
            </div>
          </div>

          <div className="">
           
           <div className="myCarousel">
             <h3 className="pt-5">Zane Erickson</h3>
             <h4>Client</h4>
             <p>
             I chose to work with the team at Varsa after watching how they looked after a client of mine.  Since 2018 Varsa continues to be an easy choice, true value, personable customer service, and comfortable living are just some of the benefits I have experienced.  You can rest easy with these Greek windows protecting your home from the elements while providing incredible light transmittance and thermal insulation.
             </p>
           </div>
         </div>

         <div className="">
           
           <div className="myCarousel">
             <h3 className="pt-5">Marc Calusin</h3>
             <h4>Client</h4>
             <p>
             First time working with this team on one of our commercial projects.  We had a deficiency issue which the owner/team rectified in a very professional matter….very hard to find tradesman that are this thorough.  Will be using them again .

             </p>
           </div>
         </div>


        </Carousel>
      );
    }
  }