import React from "react";
import munton from "./../assets/resources/munton.webp";
import conceil from "./../assets/resources/conceil.webp";
import burglar from "./../assets/resources/burglar.webp";
import double from "./../assets/resources/dual.webp";
import triple from "./../assets/resources/triple.webp";
import acoustic from "./../assets/resources/acoustic.webp";
import laminated from "./../assets/resources/laminated.webp";
import tempered from "./../assets/resources/tempered.webp";
import g4 from "./../assets/resources/GU.webp";
import thumb from "./../assets/resources/thumb.webp";
import led from "./../assets/resources/led.webp";

const Resources = () => {
  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        FEATURES
      </h1>

      <h3 className="font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8 pb-6 md:pb-12">
        OPTIONAL FEATURES
      </h3>

      <div className="  flex flex-col justify-center pb-32">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 max-w-sm md:max-w-6xl mx-auto bg-white pb-6">
          <div className="  w-full md:3/3 md:grid place-items-left md:p-4 pb-8 ">
            <img
              src={munton}
              alt="Image of munton bars on window"
              className="object-cover w-full h-full"
            />
            <span className="font-montserrat font-bold text-left  pt-2 text-sm tracking-wide ">
              Muntin Bars
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
              Muntin bars, meticulously crafted with precision, add a touch of
              elegance and character to our windows, enhancing both their
              aesthetic appeal and architectural style.
            </p>
          </div>

          <div className="  w-full md:3/3 md:grid place-items-left md:p-4 pb-8">
            <img
              src={[conceil]}
              alt="Image of concealed hinge system"
              className="object-cover w-full h-full"
            />

            <span className="font-montserrat font-bold text-left  pt-2 text-sm tracking-wide">
              Concealed Hinge
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
              Our windows feature a concealed hinge system, seamlessly blending
              in to provide a sleek and modern aesthetic while ensuring smooth
              operation and ease of maintenance.
            </p>
          </div>

          <div className="  w-full md:3/3 md:grid place-items-left md:p-4 pb-8">
            <img
              src={burglar}
              alt="Image of burglar proof laminated windows"
              className="object-cover w-full h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Burglar Proof Lamination
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
              With our burglar-proof lamination, we prioritize your safety and
              security by providing an additional layer of protection to our
              windows without compromising on style or elegance.
            </p>
          </div>
        </div>
        <h3 className="font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8 pb-12">
          GLASS OPTIONS
        </h3>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 max-w-sm md:max-w-6xl mx-auto bg-white pb-6">
          <div className="  w-full md:3/3 md:grid md:place-items-center place-items-left md:p-4 pb-8 ">
            <img
              src={[double]}
              alt="Image of double pane glass"
              className="object-cover md:w-1/2  h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Double Glazed
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Elevate your space with our double glazed windows. These windows incorporate two panes of glass with a layer of insulating air or gas in between. Enjoy improved energy efficiency, reduced noise, and enhanced thermal comfort.
            </p>
          </div>

          <div className=" w-full md:3/3 md:grid md:place-items-center place-items-left md:p-4 pb-8">
            <img
              src={triple}
              alt="Image of triple pane glass"
              className="object-cover md:w-1/2 h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Triple Glazed
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            For ultimate performance and comfort, choose our triple glazed windows. These windows boast three panes of glass, delivering exceptional insulation against the elements, maximum soundproofing, and superior energy efficiency. 
            </p>
          </div>
        </div>


        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 max-w-sm md:max-w-6xl mx-auto bg-white pb-6">
          <div className="  w-full md:3/3 md:grid place-items-left md:p-4 pb-8 ">
            <img
              src={acoustic}
              alt="image of acoustical glass"
              className="object-cover w-full h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Acoustic
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Immerse yourself in tranquility with our acoustic glass solutions. Crafted to reduce noise pollution, these windows offer a serene sanctuary within your space. Experience a peaceful ambiance while maintaining a sleek and stylish look.
            </p>
          </div>

          <div className="w-full md:3/3 md:grid place-items-left md:p-4 pb-8">
            <img
              src={[laminated]}
              alt="image of laminated glass"
              className="object-cover w-full h-full"
            />

            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Laminated
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Prioritizing your safety and security, our laminated glass windows feature a specialized interlayer that adds an extra layer of protection. Enjoy peace of mind without sacrificing elegance and timeless design.
            </p>
          </div>

          <div className="w-full md:3/3 md:grid place-items-left md:p-4 pb-8">
            <img
              src={tempered}
              alt="image of tempered glass"
              className="object-cover w-full h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Tempered
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Embrace durability and safety with our tempered glass windows. Engineered for strength, these windows are heat-treated to withstand extreme forces allowing you to enhance the security and resilience of your space.
            </p>
          </div>
        </div>

        <h3 className="font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8 pb-12">
          HARDWARE OPTIONS
        </h3>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 max-w-sm md:max-w-6xl mx-auto bg-white pb-6">
          <div className="  w-full md:3/3 md:grid place-items-left md:p-4 pb-8 ">
            <img
              src={g4}
              alt="Image of GU hardware"
              className="object-cover w-full h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              GU Locks
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Elevate your security with GU locks. Three or Five point precision-engineered locking mechanisms provide top-tier protection for your windows and doors. GU locks offer both safety and style.
            </p>
          </div>

          <div className="   w-full md:3/3 md:grid place-items-left md:p-4 pb-8">
            <img
              src={[thumb]}
              alt="Image of Thumbturn locks"
              className="object-cover w-full h-full"
            />

            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              Thumbturns
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Experience convenience at your fingertips with our thumbturns. These user-friendly accessories allow easy and quick locking and unlocking of your doors. Enhance your modern and streamlined aesthetic.
            </p>
          </div>

          <div className="  w-full md:3/3 md:grid place-items-left md:p-4 pb-8">
            <img
              src={led}
              alt="Image of Led Lights"
              className="object-cover w-full h-full"
            />
            <span className="font-montserrat font-bold text-left pt-2 text-sm tracking-wide ">
              LED Lights
            </span>
            <p className=" text-left .leading-8 pt-2 font-montserrat font-medium text-md tracking-narrow ">
            Illuminate your space with our LED lights designed for doors. These energy-efficient lighting solutions not only enhance the ambiance but also add a touch of sophistication to your interiors. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
