import React from "react";
import { Link } from "react-router-dom"; // Import the Link component

import sf85 from "../../../assets/sf85/sf85.webp";
import sf85d from "../../../assets/sf85/sf85d.webp";
import sf85f from "../../../assets/sf85/sf85f.webp";

const Bifold = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        BIFOLD DOORS
      </h1>



      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-24 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={sf85f}
              alt="Doors cover"
              className="object-cover h-full w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR BIFOLD DOORS
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Varsa Windows and Doors offer the perfect solution for large openings.
              Commonly reffered to as Nano Doors, Accordian Doors, and folding doors can be made to any size you desire!
              The bifold system is ideal for creating a vast open air concept in your home. 

             
            </p>

            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Enhance your opening with large glass panels for a seamless indoor-outdoor transition.
              Available in double or triple-glazed options for superior thermal and sound insulation,
              our doors are thermally insulated and include 4 point locking mechanisms for added security.
              Choose from various finishes and hardware options to match your style.
            </p>

          </div>
        </div>
      </div>


      

      <div className="flex flex-col justify-center pt-2 pb-2">
        <div className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto bg-white">
          <div className="w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105">
            <Link to="sf85">
              <img
                src={sf85}
                alt="Bifold Doors cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SUPREME SF85
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bifold;
