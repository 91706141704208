import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Collections from "./components/Collections";
import Doors from "./components/Doors/Doors";
import Bifold from "./components/Doors/Bifold/Bifold";
import Sf85 from "./components/Doors/Bifold/Sf85";
import LiftAndSlide from "./components/Doors/Lift/LiftAndSlide";
import S700 from "./components/Doors/Lift/S700";
import E50 from "./components/Doors/Lift/E50";
import Slider from "./components/Doors/Slide/Slider";
import S650 from "./components/Doors/Slide/S650";
import Entrance from "./components/Doors/Entrance/Entrance";
import Sd115 from "./components/Doors/Entrance/Sd115"
import Patio from "./components/Doors/Patio/Patio";
import Swing from "./components/Doors/Swing/Swing";
import Sd77 from "./components/Doors/Swing/Sd77";
import Sd95 from "./components/Doors/Swing/Sd95";
import Windows from "./components/Windows/Windows";
import Alumil from "./components/Windows/Alumil/Alumil";
import S77 from "./components/Windows/Alumil/S77";
import S77Phos from "./components/Windows/Alumil/S77Phos";
import S91 from "./components/Windows/Alumil/S91";
import Elvial from "./components/Windows/Elvial/Elvial";
import W77 from "./components/Windows/Elvial/W77";
import Etem from "./components/Windows/Etem/Etem";
import E75 from "./components/Windows/Etem/E75";
import Rehau from "./components/Windows/Rehau/Rehau";
import Koemmerling from "./components/Windows/Koemmerling/Koemmerling";
import Double from "./components/Windows/Koemmerling/Double76";
import Center from "./components/Windows/Koemmerling/Center76";
import Curtain from "./components/CurtainWalls/Curtain";
import M7 from "./components/CurtainWalls/M7";
import E85 from "./components/CurtainWalls/E85";
import Passive from "./components/PassiveHousing/Passive";
import Gallery from "./components/Gallery";
import Resources from "./components/Resources";
import Contact from "./components/Contactus";
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
// CHANGE IMAGES ON UNIQUE ID TO SPAN FULL AND TECH SPECS FULL WIDTH ACROSS W-7XL
// WHen on system jump directly into it rather than selection screen like systems with multiple
// WE use ELVIAL W77ST AI2_MD (mostly) and W77ST AI2_ID
// CREATE NEW LOGO FOR ASTRA
// BUSINESS CARDS FOR VARWSA


function App() {
  return (
    <div className="App">
      <Navbar />
     
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/doors" element={<Doors />} />
        <Route path="/doors/bifold" element={<Bifold />} />
        <Route path="/doors/bifold/sf85" element={<Sf85 />} />
        <Route path="/doors/liftandslide" element={<LiftAndSlide />} />
        <Route path="/doors/liftandslide/s700" element={<S700 />} />
        <Route path="/doors/liftandslide/e50" element={<E50 />} />
        <Route path="/doors/slider" element={<Slider />} />
        <Route path="/doors/slider/s650" element={<S650 />} />
        <Route path="/doors/slider/s700" element={<S700 />} />
        <Route path="/doors/slider/e50" element={<E50 />} />
        <Route path="/doors/pivotdoors" element={<Entrance />} />
        <Route path="/doors/pivotdoors/sd115" element={<Sd115 />} />
        <Route path="/doors/patio" element={<Patio />} />
        <Route path="/doors/patio/e75" element={<E75 />} />
        <Route path="/doors/swing" element={<Swing />} />
        <Route path="/doors/swing/sd77" element={<Sd77 />} />
        <Route path="/doors/swing/sd95" element={<Sd95 />} />
        <Route path="/windows" element={<Windows />} />
        <Route path="/windows/alumil" element={<Alumil />} />
        <Route path="/windows/alumil/s77" element={<S77 />} />
        <Route path="/windows/alumil/s77phos" element={<S77Phos />} />
        <Route path="/windows/alumil/s91" element={<S91 />} />
        <Route path="/windows/elvial" element={<Elvial />} />
        <Route path="/windows/elvial/w77" element={<W77 />} />
        <Route path="/windows/etem" element={<Etem />} />
        <Route path="/windows/etem" element={<Etem />} />
        <Route path="/windows/etem/e75" element={<E75 />} />
        <Route path="/windows/rehau" element={<Rehau />} />
        <Route path="/windows/koemmerling" element={<Koemmerling />} />
        <Route path="/windows/koemmerling/double76" element={<Double />} />
        <Route path="/windows/koemmerling/center76" element={<Center />} />
        <Route path="/curtain" element={<Curtain />} />
        <Route path="/curtain/m7" element={<M7 />} />
        <Route path="/curtain/e85" element={<E85 />} />
        <Route path="/passive" element={<Passive />} />
        <Route path="/passive/sd95" element={<Sd95 />} />
        <Route path="/passive/s91" element={<S91 />} />
        <Route path="/passive/rehau" element={<Rehau />} />
        <Route path="/passive/m7" element={<M7 />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      </ScrollToTop>
     
      
      <Footer />
    </div>
  );
}

export default App;
