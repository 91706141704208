import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import crystalize from "../../../assets/entrance/crystalize.webp";
import platinum from "../../../assets/entrance/platinum.webp";
import pivot from "../../../assets/entrance/pivot.webp";
import sd115Cover from "../../../assets/entrance/sd115Cover.webp";
import pivotCover from "../../../assets/entrance/PivotCover.webp";

const Entrance = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        PIVOT DOORS
      </h1>

      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-24 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={pivotCover}
              alt="Doors cover"
              className="object-cover h-full w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR PIVOT DOORS
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Varsa Windows and Doors provide a stunning solution for modern entryways with our pivot doors. These doors feature a central hinge that allows for smooth and effortless opening, creating a dramatic entrance.




             
            </p>

            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Pivot doors are available in various sizes and finishes with biometric unlock, led lighting, custom design, and more offering flexibility in design to match your home's aesthetic. With options for double or triple glazing, they provide excellent thermal and sound insulation. Enhanced security features include multi-point locking mechanisms, ensuring safety without compromising style.            </p>

          </div>
        </div>
      </div>
      

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">

        <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
        <Link to="sd115">
              <img
                src={sd115Cover}
                alt="crystalze door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              Alumil SD115
            </span>
          </div>


          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="https://tehni.eu/wp-content/uploads/2020/02/TEHNI-ALUL-CRYST-20.pdf">
              <img
                src={crystalize}
                alt="crystalze door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              Aluline - Crystallize
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="https://tehni.eu/wp-content/uploads/2023/05/TLS-SUPERIOR-2023.pdf">
              <img
                src={platinum}
                alt="platinum door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              Superior platinum{" "}
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="https://tehni.eu/wp-content/uploads/2022/09/TEHNi-PIVOT-Superior-22.pdf">
              <img
                src={pivot}
                alt="pivot door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              Pivot{" "}
            </span>
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
         
        </div>
      </div>
    </div>
  );
};

export default Entrance;
