export const links = [
  
  {
    name: "Collections",
    submenu: true,
    sublinks: [
      {
        Head: "",
        sublink: [
          { name: "Doors", link: "doors" }
        ],
      },
      {
        Head: "",
        sublink: [
          { name: "Windows", link: "windows" }
        ],
      },
      {
        Head: "",
        sublink: [
          { name: "Curtain Walls", link: "curtain" }
        ],
      },
      {
        Head: "",
        sublink: [
          { name: "Passive Housing", link: "passive" }
        ],
      },
    ],
  }]
  