import React from "react";
import g1 from "./../assets/gallery/g1.webp";
import g2 from "./../assets/gallery/g2.webp";
import g3 from "./../assets/gallery/g3.webp";
import g4 from "./../assets/gallery/g4.webp";
import g5 from "./../assets/gallery/g5.webp";
import g6 from "./../assets/gallery/g6.webp";
import g7 from "./../assets/gallery/g7.webp";
import g8 from "./../assets/gallery/g8.webp";
import g9 from "./../assets/gallery/g9.webp";
import g10 from "./../assets/gallery/g10.webp";
const Gallery = () => {
  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        GALLERY
      </h1>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 max-w-xl md:max-w-6xl mx-auto bg-black ">
          <div className=" w-full md:2/2 bg-black grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g1}
                alt="Image of lift and slide door"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
          <div className=" w-full md:w-2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g2}
                alt="image of lift and slide door"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g3}
                alt="image of lift and slide door"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
          <div className=" w-full md:w-2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g4}
                alt="image of alumil window"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g5}
                alt="European tilt and turn window"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
          <div className=" w-full md:w-2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g6}
                alt="Curtain wall commercial project"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g7}
                alt="European passive house project"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
          <div className=" w-full md:w-2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g8}
                alt="European tilt and turn window"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g9}
                alt="alumil s67 window"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
          <div className=" w-full md:w-2/2 bg-white grid place-items-center transform transition duration-500 hover:scale-105 ">
            <a href="/">
              <img
                src={g10}
                alt="alumil window system"
                className="object-cover w-full h-full"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
