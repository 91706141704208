import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="bg-[#f1f1f1] ">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <a href="/" className="hover:underline">
              Varsa
            </a>
            . All Rights Reserved.
          </span>
          {/* <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
            <li>
              <a href="/contact" className="hover:underline ">
                Contact
              </a>
            </li>
          </ul> */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
