import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import sd77 from "../../../assets/sd77/sd77.webp";
import sd95 from "../../../assets/sd95/sd95Passive.webp";
import tls90 from "../../../assets/entrance/tls90.webp";
import tls65 from "../../../assets/entrance/tls65.webp";
import tls50 from "../../../assets/entrance/tls50.webp";
import crystalize from "../../../assets/entrance/crystalize.webp";
import platinum from "../../../assets/entrance/platinum.webp";

const Swing = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        SWING DOORS
      </h1>

      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-24 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={crystalize}
              alt="Doors cover"
              className="object-cover h-full w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR ENTRANCE DOORS
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Varsa Windows and Doors offer the perfect solution for grand entrances with our entrance swing doors. Designed for elegance and durability, these doors provide a traditional yet sophisticated look for any home.
            </p>

            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
            Our entrance swing doors are available in various finishes and styles to complement your design preferences. They come with options for double or triple glazing, providing excellent thermal and sound insulation. Enhanced security features include multi-point locking mechanisms, ensuring safety without compromising on style.

          </p>
          </div>
        </div>
      </div>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-4xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="sd77">
              <img
                src={sd77}
                alt="Alumil sd77 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SMARTIA SD77
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="sd95">
              <img
                src={sd95}
                alt="Alumil sd95 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SUPREME SD95
            </span>
          </div>
          
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
        <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link  to="https://tehni.eu/wp-content/uploads/2023/05/TLS-SUPERIOR-2023.pdf">
              <img
                src={tls90}
                alt="tls 90 door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              TLS 90{" "}
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link  to="https://tehni.eu/wp-content/uploads/2017/09/TLS-65-75.pdf">
              <img
                src={tls65}
                alt="tls 65 door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              TLS 65{" "}
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="https://tehni.eu/wp-content/uploads/2017/08/TLS-50-Brochure-17-eng-1.pdf">
            
              <img
                src={tls50}
                alt="tls 50 door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              TLS 50 smart{" "}
            </span>
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-4xl mx-auto bg-white ">
        <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="https://tehni.eu/wp-content/uploads/2020/02/TEHNI-ALUL-CRYST-20.pdf">
              <img
                src={crystalize}
                alt="crystalze door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              Aluline - Crystallize
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="https://tehni.eu/wp-content/uploads/2023/05/TLS-SUPERIOR-2023.pdf">
              <img
                src={platinum}
                alt="platinum door cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              Superior platinum{" "}
            </span>
          </div>
          </div> 

      </div>
    </div>
  );
};

export default Swing;
