import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import s650 from "../../../assets/s650/s650.webp";
import s700 from "../../../assets/s700/s700.webp";
import e50 from "../../../assets/e50/e50.webp";

const Slider = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        ULTRA SLIM LINE
      </h1>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="s650">
              <img
                src={s650}
                alt="Alumil s650 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            {/* CHAANGE IMAGE TO FORWARD FACE TO SHOW SLIM BEZEL */}
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SUPREME S650 PHOS
            </span>
          </div>
          {/* <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="s700">
              <img
                src={s700}
                alt="Alumil s700 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SUPREME S700
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="e50">
              <img
                src={e50}
                alt="Etem e50 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ETEM E50
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Slider;
