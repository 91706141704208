import React from "react";
import { Link } from "react-router-dom"; // Import the Link component

import e75 from "../../../assets/etem/e75.webp";

const Patio = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        PATIO DOORS
      </h1>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="e75">
              <img
                src={e75}
                alt="Etem e75 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ETEM E75
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patio;
