import React from "react";
import w77Cover from "../../../assets/w77/w77Cover.webp";
import w77a from "../../../assets/w77/w77a.webp";
import w77b from "../../../assets/w77/w77b.webp";
import w77c from "../../../assets/w77/w77c.webp";


import w77sec1 from "../../../assets/w77/w77sec1.webp";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const W77 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ELVIAL W77
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
             Hyper Product Iconic
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={w77Cover}
                alt="Picture of a project"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
              An ingenuous take on elegance. The precise lines, slim contours and attenuated particulars that make the Iconic collection aesthetically exceptional are present in this solution. Put your creativity to work to see what it can do for you.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                
                  Unobstructed transit
                                    </li>
                  <li>Same level appearance inwards</li>
                  <li>Firm and minimal</li>
                  <li>Form follows function, they said. We glorified form by concealing the functional components.</li>
                  <li>Modern design</li>
                  <li>
                    Ease of access according to specifications for people with
                    special needs and children
                  </li>
                  <li>Hinges to open or tilt that go undetected</li>
                  <li>Handles so sophisticated that render grace to industrial design.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={w77Cover}
              alt="Picture of a project"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-3 gap-2 mx-auto">
              <div class="w-full">
                <img src={w77a} alt="image" />
              </div>
              <div class="w-full ">
                <img src={w77b} alt="image" />
              </div>
              <div class="w-full ">
                <img src={w77c} alt="image" />
              </div>
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat  md:text-base text-left pt-6 uppercase ">
                <span className="pr-10 font-semibold ">
                 SYSTEM DEPTH
                  <span className="pr-10 block font-normal pb-4">77 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
MIN FACE HEIGHT FRAME                 <span className="pr-10 block font-normal pb-4">89 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
MIN FACE HEIGHT THRESHOLD                  <span className="pr-10 block font-normal pb-4"> 79 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                 MIN FACE WIDTH
                  <span className="pr-10 block font-normal pb-4">154 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                 MAX SASH HEIGHT
                  <span className="pr-10 block font-normal pb-4">2.800 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                MAX SASH WIDTH
                  <span className="pr-10 block font-normal pb-4">1.600 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                 MAX SASH WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                    200 kg
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  MAXIMUM GLASS THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                    70 mm
                  </span>
                </span>


              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6 ">
              <span className="pr-10 pb-4 ">
               
              Single sash fixed window with skylight
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                
              Single sash opening window
              </span>
              <span className="pr-10 pb-4  ">
              
               Single sash tilt & turn window
              </span>
              <span className="pr-10 pb-4">
                {" "}
                
                Double sash opening window
              </span>
              <span className="pr-10 pb-4">
                
                Double sash tilt & turn window
              </span>
              <span className="pr-10 pb-4">
              
                  Double sash tilt & turn window with fixed
              </span>

              <span className="pr-10 pb-4 ">
                
             Special typology window wall with tilt & turn window
              </span>
              <span className="pr-10 pb-4  ">
                
               Special typology angular construction
              </span>
              
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>

        </div>
      </div>
      <div className="  flex flex-col justify-left  pb-2">
        <div className="  md:max-w-7xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 bg-white ">
          <div className="  md:w-1/3 md:2/2 grid place-items-center  ">
            <img
              src={w77sec1}
              alt="Team image"
              className="object-cover w-full h-full "
            />
          </div>

        </div>
      </div>

      {/* <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/smartia-brochure-gr-en.pdf?sfvrsn=2dc3db89_78"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Smartia Aluminum Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/pre-qualification-sd77f4e3a81aba954c1b8c233f00e0788cd4.pdf?sfvrsn=2ebdd2d8_31"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S77 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/smartia-s77-en.pdf?sfvrsn=9f4a77ee_59"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S77 Brochure
          </a>
        </div> */}
        
      
    </div>
  );
};

export default W77;
